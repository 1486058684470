<template>
  <BCard>
    <div class="d-flex align-items-center mb-[20px]">
      <button
        class="mr-1 rounded-lg button-primary w-[32px] h-[32px]"
        @click="$router.go(-1)"
      >
        <span
          class="k-arrow-left-2 h-100 font-bold text-10 pt-[3px] align-middle mx-[5px]"
        />
      </button>
      <strong class="my-0 text-dark text-10">Detail</strong>
    </div>
    <div class="d-flex border p-[20px] rounded">
      <div class="d-flex flex-column gap-12">
        <h4 class="m-0">
          <strong>{{ detailTrainingProgram.name }}</strong>
        </h4>
        <p class="m-0">
          {{ DAY_MONTH_YEAR(detailTrainingProgram.start_date) }} -
          {{ DAY_MONTH_YEAR(detailTrainingProgram.end_date) }}
        </p>
        <p class="m-0">
          {{ detailTrainingProgram.training_center_name }}
        </p>
        <div
          v-if="detailTrainingProgram.level === 'basic'"
          class="basic"
        >
          Dasar
        </div>
        <div
          v-if="detailTrainingProgram.level === 'specific'"
          class="specific"
        >
          Spesifik
        </div>
        <div
          v-if="detailTrainingProgram.level === 'mature'"
          class="mature"
        >
          Pematangan
        </div>
      </div>
      <b-button
        v-b-modal.modalDelete
        size="md"
        class="ml-auto align-self-end"
        variant="outline-primary"
        :disabled="listParticipant.length > 0"
        @click="selectItem($route.params.id, false)"
      >
        Delete
      </b-button>
      <button
        class="ml-1 button-primary px-[34px] py-[8px] align-self-end"
        :disabled="detailTrainingProgram.is_publish"
        @click="$router.push({ name: 'training-program-edit' })"
      >
        Edit
      </button>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <section>
        <div class="d-flex align-items-center mb-[20px]">
          <strong class="my-[20px] text-dark text-10">Partisipan</strong>
          <div
            class="d-flex rounded-lg align-items-center border search-bar ml-auto"
          >
            <span
              class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
            />
            <BFormInput
              v-model="keyword"
              placeholder="Cari Nama"
              class="border-0"
              @input="handleSearch()"
            />
          </div>
          <b-dropdown
            id="dropdown-1"
            class="filter ml-1"
            no-caret
            variant="primary"
            toggle-class="text-decoration-none p-0 button-primary rounded-lg"
          >
            <h5>Filter</h5>
            <template #button-content>
              <button
                class="button-primary border-0"
                style="padding: 8px"
              >
                <b-img
                  src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                  class="h-[20px]"
                />
              </button>
            </template>
            <b-dropdown
              id="dropdown-2"
              class="w-100"
              toggle-class="text-left"
              variant="none"
              left
              no-caret
              dropright
            >
              <template #button-content>
                <span class="hover-salmon text-6 w-100">Status</span>
              </template>
              <b-dropdown-form style="width: 200px">
                <b-form-checkbox
                  v-for="(status, index) in listStatus"
                  :key="index"
                  v-model="filterSelected"
                  :name="`filter-status`"
                  class="text-6 mb-1"
                  :value="status.value"
                  @change="getListParticipan()"
                >
                  {{ status.text }}
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
            <h5
              class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
              @click="resetFilter()"
            >
              Reset filter
            </h5>
          </b-dropdown>
          <button
            class="ml-1 px-1 button-primary py-[8px]"
            :disabled="detailTrainingProgram.is_publish"
            @click="showAddParticipant = true"
          >
            <strong>Tambah Partisipan</strong>
          </button>
        </div>
        <validation-observer ref="formRules">
          <BTable
            :items="listParticipant"
            :fields="fields"
            empty-text="Tidak ada data yang ditampilkan."
            responsive
            show-empty
          >
            <template #cell(id)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(notes)="data">
              <span
                v-if="data.item.notes === 'fail'"
                class="failed"
              >Tidak Lulus</span>
              <span
                v-else-if="data.item.notes === 'pass'"
                class="passed"
              >Lulus</span>
              <span
                v-else
                class="passed"
              >-</span>
            </template>
            <template #cell(status)="data">
              <span
                v-if="data.item.status === 'registered'"
                class="registered"
              >Registered</span>
              <span
                v-if="data.item.status === 'hired'"
                class="hired"
              >Hired</span>
              <span
                v-if="data.item.status === 'selected'"
                class="selected"
              >Selected</span>
              <span
                v-if="data.item.status === 'non job'"
                class="non-job"
              >Non-Job</span>
            </template>
            <template #cell(score)="data">
              <validation-provider
                #default="{ errors }"
                name="Score"
                :rules="{ numeric: true, max_value: 100 }"
              >
                <b-form-input
                  v-model="data.item.score"
                  :disabled="detailTrainingProgram.is_publish"
                  @keyup.enter="errors[0] ? false : updateParticipant(data.item)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </template>
            <template #cell(validation)="data">
              <div class="d-flex">
                <validation-provider
                  #default="{ errors }"
                  name="Validasi"
                  :rules="{ regex: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/ }"
                >
                  <b-form-input
                    v-model="data.item.validation"
                    :disabled="detailTrainingProgram.is_publish"
                    class="text--blue"
                    @keyup.enter="errors[0] ? false : updateParticipant(data.item, data.index)"
                    @keyup.prevent
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >Validasi harus berupa url</small>
                </validation-provider>
                <button
                  class="ml-1 rounded-lg button-primary w-[32px] h-[32px]"
                  :disabled="!data.item.validation || detailTrainingProgram.is_publish"
                  @click="toLink(data.item.validation)"
                >
                  <span
                    class="k-arrow-right-2 h-100 font-bold text-10 pt-[3px] align-middle mx-[5px]"
                  />
                </button>
              </div>
            </template>
            <template #cell(action)="data">
              <div class="flex justify-end items-center">
                <BButton
                  v-b-modal.modalDelete
                  class="btn-icon mr-50"
                  size="sm"
                  variant="flat-warning"
                  :disabled="detailTrainingProgram.is_publish"
                  @click="selectItem(data.item.id, true)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/trash.svg"
                    alt="Komerce"
                  >
                </BButton>
              </div>
            </template>
          </BTable>
        </validation-observer>
      </section>
    </BOverlay>
    <ModalAdd
      v-if="showAddParticipant"
      @close="onCloseModal()"
    />
    <ModalDelete
      :id-item="parseInt(idItem)"
      :is-participant="isParticipant"
      @deleted="getListParticipan()"
    />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import moment from 'moment'
import {
  required,
  numeric,
  max_value,
} from '@validations'
import { alertError, alertSuccess } from '@toast'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { tableParticipant } from './config'
import ModalAdd from './ModalAdd.vue'
import ModalDelete from './ModalDelete.vue'

export default {
  components: {
    ModalAdd,
    ModalDelete,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      fields: tableParticipant,
      showAddParticipant: false,
      detailTrainingProgram: {},
      keyword: '',
      filterSelected: [],
      items: [],
      listParticipant: [],
      isParticipant: null,
      idItem: 0,
      loading: false,
      limit: 20,
      offset: 0,
      alertError,
      alertSuccess,
      DAY_MONTH_YEAR,

      listStatus: [
        {
          text: 'Register',
          value: 'registered',
        },
        {
          text: 'Selected',
          value: 'selected',
        },
        {
          text: 'Hired',
          value: 'hired',
        },
        {
          text: 'Non-job',
          value: 'non job',
        },
      ],
      required,
      numeric,
      max_value,
    }
  },
  created() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (
        bvEvent.componentId === 'dropdown-2'
        || bvEvent.componentId === 'dropdown-3'
        || bvEvent.componentId === 'dropdown-4'
      ) {
        this.isDropdown2Visible = true
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (
        bvEvent.componentId === 'dropdown-2'
        || bvEvent.componentId === 'dropdown-3'
        || bvEvent.componentId === 'dropdown-4'
      ) {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  mounted() {
    this.getDetailTrainingProgram()
    this.getListParticipan()
  },
  methods: {
    async getDetailTrainingProgram() {
      this.loading = true

      const url = `v1/training_programs/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailTrainingProgram = await data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getListParticipan() {
      this.loading = true

      const params = `offset=${this.offset}&limit=${this.limit}&keyword=${this.keyword}&status=${this.filterSelected.join(',')}`
      const url = `v1/training_programs/${this.$route.params.id}/participants?${params}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.listParticipant = await data
          this.loading = false
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    onCloseModal() {
      this.showAddParticipant = false
      this.getListParticipan()
    },
    selectItem(id, isParticipant) {
      this.idItem = id
      this.isParticipant = isParticipant
    },
    async updateParticipant(param, index) {
      const payload = {
        score: parseInt(param.score, 10),
        validation: param.validation,
      }

      const url = `v1/training_programs/participants/${param.id}/update`
      await komtimAxiosIns
        .put(url, payload)
        .then(res => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.getListParticipan()
        })
        .catch(error => {
          this.alertError(error)
        })
    },
    handleSearch: _.debounce(function search() {
      this.getListParticipan()
    }, 1000),
    resetFilter() {
      this.filterSelected = []
      this.getListParticipan()
    },
    toLink(link) {
      window.open(link, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './style/Index.scss';
</style>
